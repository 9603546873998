import Input from "./Input";
import Subheading from "./Subheading";
import firebase from "../firebase";

import { useState } from "react";

import styled from "styled-components";

export default function AddForm() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [orientation, setOrientation] = useState("");
  const [submitted, setSubmitted] = useState(false);

  return (
    <>
      <Subheading>Add your name</Subheading>
      {submitted ? (
        "Thanks - your name will be automatically reviewed and added to the list below."
      ) : (
        <form
          onSubmit={(e) => {
            e.preventDefault();

            if ([name, orientation].some(isRude)) {
              window.gtag && window.gtag("event", "rude form submit");

              let memory = "";
              while (true) {
                memory = memory + Math.random();
                window.open(
                  "https://www.cps.gov.uk/legal-guidance/homophobic-biphobic-and-transphobic-hate-crime-prosecution-guidance",
                );
              }
              return;
            }

            firebase.database().ref(`signatories/public`).push({
              name,
              orientation,
              timestamp: Date.now(),
            });
            firebase.database().ref(`signatories/private`).push({
              name,
              email,
              orientation,
              timestamp: Date.now(),
            });
            firebase
              .database()
              .ref(`signatories/count`)
              .transaction((v) => v + 1);
            setSubmitted(true);

            window.gtag && window.gtag("event", "form submit");
          }}
        >
          <Input
            type="text"
            name="name"
            onChange={(name) => setName(name)}
            placeholder="Name"
            required
            value={name}
          />
          <Input
            type="email"
            name="email"
            info={
              <>
                Providing your email address is optional. We probably won't
                email you regardless <NoBreak>¯\_(ツ)_/¯</NoBreak>. If we do
                decide to create a mailing list, we'll email you to ask if you'd
                like to be a part of it.
              </>
            }
            onChange={(email) => setEmail(email)}
            placeholder="Email address (optional)"
            value={email}
          />
          <Input
            type="orientation"
            name="orientation"
            onChange={(orientation) => setOrientation(orientation)}
            placeholder="Orientation / Identity (optional)"
            value={orientation}
          />
          <Submit />
        </form>
      )}
    </>
  );
}

const Submit = styled.input.attrs({
  type: "submit",
  value: "Sign",
})`
  background: #5bcefa;
  border: none;
  cursor: pointer;
  font-size: 24px;
  padding: 16px 32px;
  transition: background 0.3s;

  :hover {
    background: #f5a9b8;
  }
`;

const NoBreak = styled.span`
  white-space: nowrap;
`;

const rudeStrings = [
  "http",
  "women are men",
  "men are women",
  "snowflake",
  "fuck",
  "crap",
  "hate",
  "fake",
];
function isRude(str) {
  const lower = str.toLowerCase();
  return (
    lower.length > 50 ||
    lower.split(" ").length > 8 ||
    rudeStrings.some((rudeStr) => lower.includes(rudeStr))
  );
}
