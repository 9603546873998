import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./react/App";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root"),
);

function sendToAnalytics({ id, name, value }) {
  window.gtag("event", name, {
    event_category: "vitals",
    value: Math.round(name === "CLS" ? value * 1000 : value), // values must be integers
    event_label: id, // id unique to current page load
    non_interaction: true, // avoids affecting bounce rate
  });
}

reportWebVitals(sendToAnalytics);
