import Subheading from "./Subheading";
import firebase from "../firebase";

import styled from "styled-components";
import { useList, useObjectVal } from "react-firebase-hooks/database";

export default function List() {
  const count = useObjectVal(firebase.database().ref(`signatories/count`));

  return (
    <>
      <Subheading>
        Signatories
        {count != null && <> ({count})</>}
      </Subheading>
      <Signatories />
    </>
  );
}

function Signatories() {
  const [signatories, loading, error] = useSignatories();

  if (loading) {
    return "Loading...";
  } else if (error) {
    console.error(error);
    return "Error loading signatories";
  }

  return [...signatories]
    .reverse()
    .map((signatory) => (
      <Signatory key={signatory.key} signatory={signatory} />
    ));
}

const Signatory = styled.div.attrs(({ signatory }) => {
  const val = signatory.val();
  const name = val.name.trim();
  const orientation = val.orientation.trim();

  return {
    children: orientation ? `${name} (${orientation})` : name,
  };
})`
  font-size: 18px;
`;

function useSignatories() {
  return useList(firebase.database().ref(`signatories/public`));
}
