import Info from "./Info";

import styled from "styled-components";

export default function Input({ info, onChange, ...props }) {
  return (
    <Wrapper>
      <StyledInput {...props} onChange={(e) => onChange(e.target.value)} />
      {info != null && <StyledInfo text={info} />}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-bottom: 12px;
  margin-left: auto;
  margin-right: auto;
  max-width: 340px;
  position: relative;
`;

const StyledInput = styled.input`
  border: none;
  border-bottom: 1px solid;
  box-sizing: border-box;
  font-size: 24px;
  padding: 8px;
  width: 100%;
`;

const StyledInfo = styled(Info)`
  margin-left: 12px;
  left: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
`;
