import flag from "../flag.svg";
import AddForm from "./AddForm";
import List from "./List";

import styled from "styled-components";

export default function App() {
  return (
    <Root>
      <Flag src={flag} alt="LGBT Flag" />
      <Heading>LGBT+ Alliance</Heading>
      <MainText>
        <p>
          We are a group of lesbian, gay, bisexual, trans, and queer folk who
          support the rights of transgender people to live in accordance with
          their identities, openly and free from discrimination.
        </p>
        <p>
          We recognise that the fight for LGBT rights is one where LGB and T are
          stronger together, and that trans people have been instrumental in the
          massive strides made in social progress on this issue.
        </p>
        <p>
          The notion that trans identities are a threat to cisgender LGB people
          is not only an alarmist fringe idea unsupported by the vast majority
          of cis LGB individuals and organisations, but also a betrayal that
          seeks to leave even more vulnerable a group that is already societally
          marginalised and suffering greatly for it.
        </p>
      </MainText>
      <AddForm />
      <List />
    </Root>
  );
}

// Helpers

const Root = styled.div`
  margin: 0 auto;
  max-width: 800px;
  padding: 40px;
  text-align: center;
`;

const Flag = styled.img`
  height: 100px;
  width: 100px;
`;

const Heading = styled.h1`
  font-size: 48px;
`;

const MainText = styled.article`
  font-size: 18px;
`;
