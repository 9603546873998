import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/database";

firebase.initializeApp({
  apiKey: "AIzaSyA1UgrHZmTSpAwQ2FKwKPGR3gxE3GZuMwQ",
  authDomain: "lgbt-alliance.firebaseapp.com",
  databaseURL: "https://lgbt-alliance.firebaseio.com",
  projectId: "lgbt-alliance",
  storageBucket: "lgbt-alliance.appspot.com",
  messagingSenderId: "928995601158",
  appId: "1:928995601158:web:916fe5c933dfa36c560c87",
  measurementId: "G-43D2SBJWJ5"
});
firebase.analytics();

export default firebase;