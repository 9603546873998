import info from "../info.svg";

import { useState } from "react";
import styled from "styled-components";

export default function Input({ text, ...props }) {
  const [open, setOpen] = useState(false);

  return (
    <Wrapper {...props}>
      <Image
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
      />
      <Text className={open ? "visible" : ""}>{text}</Text>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  width: 24px;
  z-index: 1;
`;

const Image = styled.img.attrs({
  src: info,
})`
  opacity: 0.6;
  transition: opacity 0.2s;
  width: 100%;

  :hover {
    opacity: 0.8;
  }
`;

const Text = styled.div`
  background: white;
  border: 1px solid;
  border-radius: 5px;
  box-sizing: border-box;
  margin-right: 22px;
  max-width: 320px;
  opacity: 0;
  padding: 20px;
  pointer-events: none;
  position: absolute;
  right: 100%;
  top: 0;
  transition: opacity 0.2s;
  width: 80vw;

  &.visible {
    opacity: 1;
  }
`;
